import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';

import Loading from '../../components/Loading';
import './Login.css';

function Login(): JSX.Element {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout
  } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <p className="mb-4">
            Oops! There was a login error. Please use your Foxquilt email
            address to login.
          </p>
          <button
            className="bg-secondary"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Proceed
          </button>
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    return <Redirect to="/invoice" />;
  }

  return (
    <div className="m-auto text-center">
      <p className="text-2xl mb-20 font-bold">PLEASE SIGN IN</p>
      {!isAuthenticated ? (
        <button
          className="bg-tertiary py-2 px-6 text-base font-bold rounded-md"
          onClick={loginWithRedirect}
        >
          INTIATE LOGIN
        </button>
      ) : (
        <button
          className="bg-tertiary py-2 px-6 text-base font-bold rounded-md"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          LOGOUT
        </button>
      )}
    </div>
  );
}

export default Login;
