import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { Scalars } from '../../generated/graphql';

export interface InvoiceInfoState {
  invoiceNumber: string;
  invoiceDate: string;
  quoteId: string;
  totalPremium: string;
  tax: string;

  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  clientContactNumber: string;
  clientEmail: string;
  clientAddressStreet: string;
  clientAddressCity: string;
  clientAddressProvince: Scalars['CanadaProvince'];
  clientAddressPostalCode: string;

  policyDetailsEffectiveDate: string;
  policyDetailsDescription: Option[];
  policyDetailsNotes: string;
}

export const defaultInvoiceInfo: InvoiceInfoState = {
  invoiceNumber: '',
  invoiceDate: '',
  quoteId: '',
  totalPremium: '',
  tax: '',

  clientFirstName: '',
  clientMiddleName: '',
  clientLastName: '',
  clientContactNumber: '',
  clientEmail: '',
  clientAddressStreet: '',
  clientAddressCity: '',
  clientAddressProvince: 'Ontario',
  clientAddressPostalCode: '',

  policyDetailsEffectiveDate: '',
  policyDetailsDescription: [],
  policyDetailsNotes: ''
};
