import React, { useRef } from 'react';

import { PaymentMethod } from '../../generated/graphql';
import useRunIfInvoiceNumberExists from '../../hooks/useRunIfInvoiceNumberExists';
import calTotalPayable from '../../utils/calTotalPayable';
import { InvoiceInfoState } from './InvoiceData';
import { InvoiceEvent } from './type';

interface ClientDetailsProps {
  invoiceData: InvoiceInfoState;
  handleInvoiceInputChange: (event: InvoiceEvent) => void;
}

const InvoiceDetails: React.FC<ClientDetailsProps> = (props) => {
  const invoiceNumberInputRef = useRef<HTMLInputElement>(null);
  const { invoiceData, handleInvoiceInputChange } = props;
  const { invoiceNumber } = invoiceData;

  useRunIfInvoiceNumberExists(invoiceNumber, (isExisted) => {
    const errorMsg = isExisted ? 'Invoice number is already registered' : '';
    invoiceNumberInputRef.current?.setCustomValidity(errorMsg);
  });

  const totalPremium = invoiceData.totalPremium;
  const tax = invoiceData.tax;
  const { creditCardFee, totalPayable } = calTotalPayable(
    { totalPremium: +totalPremium, tax: +tax },
    PaymentMethod.CreditCard
  );
  return (
    <table className="m-4 custom-table">
      <thead>
        <tr>
          <th colSpan={2}>INVOICE MANAGEMENT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>INVOICE NUMBER</td>
          <td>
            <input
              type="text"
              name="invoiceNumber"
              required
              value={invoiceNumber}
              onChange={handleInvoiceInputChange}
              ref={invoiceNumberInputRef}
            />
          </td>
        </tr>
        <tr>
          <td>INVOICE DATE</td>
          <td>
            <input
              type="date"
              name="invoiceDate"
              required
              value={invoiceData.invoiceDate}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>QUOTE NUMBER</td>
          <td>
            <input
              type="text"
              name="quoteId"
              required
              value={invoiceData.quoteId}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>PREMIUM AMOUNT</td>
          <td>
            <input
              type="number"
              name="totalPremium"
              required
              value={totalPremium.toString()}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>TAXES</td>
          <td>
            <input
              type="number"
              name="tax"
              required
              value={tax.toString()}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>CREDIT CARD FEES</td>
          <td>${creditCardFee.toFixed(2)}</td>
        </tr>
        <tr>
          <td>TOTAL PAYABLE</td>
          <td>${totalPayable.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceDetails;
