import React from 'react';
import { useContactBar } from '../../utils/hooks/useContactBarHook';

const SALES_EMAIL = 'sales@foxquilt.com';

const PaymentSuccess: React.FC = () => {
  useContactBar();

  return (
    <p className="m-auto text-lg">
      Thank you! Payment receipt is sent to{' '}
      <a href={`mailto:${SALES_EMAIL}`} className="underline">
        {SALES_EMAIL}
      </a>
      . Please check and send it to your client.
    </p>
  );
};

export default PaymentSuccess;
