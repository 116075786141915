import React from 'react';
import './MonerisRedirectModal.css';
import Modal from '../../../components/Modal/Modal';
import getEnv from '../../../utils/getEnv';
import {
  CreatePaymentInput,
  PaymentMethod,
  useMonerisCreatePaymentMutation,
  Provider
} from '../../../generated/graphql';

const MONERIS_FORM_ID = 'moneris-preprocessing-form';
const MONERIS_HPP_ID = 'moneris_hpp_id';
const MONERIS_TICKET = 'moneris_ticket';

const getInputById = (id: string) => {
  const ret = document.getElementById(id) as HTMLInputElement;
  if (!ret) {
    throw new Error(`bug: input component not found, id: [${id}]`);
  }
  return ret;
};

interface MonerisRedirectModalProps {
  invoiceId: string;
  setClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MonerisRedirectModal: React.FC<MonerisRedirectModalProps> = ({
  invoiceId,
  setClose
}) => {
  const [monerisCreatePayment] = useMonerisCreatePaymentMutation();

  const handleMonerisModalNext = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // Get Moneris tokens from the backend

    // Fill the tokens in a HTML form and use its submission mechanism
    // to starts a Post request to Moneris.

    const createPaymentInput: CreatePaymentInput = {
      invoiceId,
      method: PaymentMethod.CreditCard,
      provider: Provider.Moneris
    };
    monerisCreatePayment({
      variables: { paymentInfo: createPaymentInput }
    }).then(({ data: monerisCreatePaymentData }) => {
      const form = document.getElementById(MONERIS_FORM_ID) as HTMLFormElement;
      if (!form) {
        throw new Error('bug: moneris preprocessing form not found');
      }
      if (monerisCreatePaymentData) {
        const {
          hpp_id,
          ticket
        } = monerisCreatePaymentData.monerisCreatePayment;
        getInputById(MONERIS_HPP_ID).value = hpp_id;
        getInputById(MONERIS_TICKET).value = ticket;
        form.submit();
      }
    });
  };

  const { REACT_APP_MONERIS_ENDPOINT } = getEnv();
  return (
    <div
      className="MonerisRedirectModal w-full"
      data-testid="MonerisRedirectModal"
    >
      <Modal
        setClose={setClose}
        onAction={handleMonerisModalNext}
        actionName={'NEXT'}
      >
        <form
          id={MONERIS_FORM_ID}
          method="post"
          action={REACT_APP_MONERIS_ENDPOINT}
          className="hidden"
        >
          <input type="hidden" id={MONERIS_HPP_ID} name="hpp_id" />
          <input type="hidden" name="hpp_preload" value="" />
          <input type="hidden" id={MONERIS_TICKET} name="ticket" />
        </form>
        <div className="px-8 py-12">
          <p>
            Moneris processes the payments for Foxquilt. After clicking NEXT you
            will be redirected to a secure payment page hosted by Moneris.
          </p>
          <p>Your information will not be shared with any third party.</p>
        </div>
      </Modal>
    </div>
  );
};

export default MonerisRedirectModal;
