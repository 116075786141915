import React from 'react';
import { PaymentMethod } from '../../../../generated/graphql';

interface RadioProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  value: PaymentMethod;
}

function radioGenerator(name: string): React.FC<RadioProps> {
  return ({ defaultChecked, disabled, value }) => (
    <input
      className="Radio"
      data-testid="Radio"
      type="radio"
      value={value}
      name={name}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  );
}

export const OptionRadio = radioGenerator('payment-method');

export const PayNowRadio = radioGenerator('pay-now-method');
