import React from 'react';

import './ContactBar.css';
import MeetingIcon from '../../../images/icons/meeting.png';
import CallIcon from '../../../images/icons/call.png';
import EmailIcon from '../../../images/icons/email.png';
import NewQuoteIcon from '../../../images/icons/newQuote.png';

const MEET_URL = 'https://meetings.hubspot.com/foxquilt/meet-with-foxquilt';
const PHONE_URL = 'tel:1-888-878-4588';
const EMAIL_URL = 'mailto:sales@foxquilt.com';
const NEW_QUOTE_URL = 'https://join.foxquilt.com/';

const ContactBar: React.FC = () => (
  <div data-testid="ContactBar" className="ContactBar">
    <div className="text-white text-center font-medium my-0 mx-auto w-auto text-lg">
      Contact Us:
    </div>
    <div className="inline-flex flex-row justify-between ">
      <a href={MEET_URL}>
        <img src={MeetingIcon} alt="Book an appointment" />
      </a>
      <a href={PHONE_URL}>
        <img src={CallIcon} alt="Call" />
      </a>
      <a href={EMAIL_URL}>
        <img src={EmailIcon} alt="Email" />
      </a>
      <a href={NEW_QUOTE_URL}>
        <img src={NewQuoteIcon} alt="New Quote" />
      </a>
    </div>
  </div>
);

export default ContactBar;
