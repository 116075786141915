import React from 'react';
import { FirstPaymentInfo } from '../../generated/graphql';

interface IPaymentDetails {
  paymentInfo: FirstPaymentInfo;
}

const PaymentDetails: React.FC<IPaymentDetails> = ({
  paymentInfo: {
    totalPremium,
    downPayment,
    amountFinanced,
    numberOfInstallments
  }
}) => (
  <table className="m-4 custom-table">
    <tbody>
      <tr>
        <th colSpan={2}>PAYMENT DETAILS</th>
      </tr>
      <tr>
        <td>TOTAL PREMIUM</td>
        <td>{totalPremium}</td>
      </tr>
      <tr>
        <td>DOWN PAYMENT</td>
        <td>{downPayment}</td>
      </tr>
      <tr>
        <td>AMOUNT FINANCED</td>
        <td>{amountFinanced}</td>
      </tr>
      <tr>
        <td>NUMBER OF INSTALLMENTS</td>
        <td>{numberOfInstallments}</td>
      </tr>
    </tbody>
  </table>
);

export default PaymentDetails;
