import React from 'react';

import { InvoiceInfoState } from './InvoiceData';
import { InvoiceEvent } from './type';
import './ClientDetails.css';

const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
] as const;

interface ClientDetailsProps {
  invoiceData: InvoiceInfoState;
  handleInvoiceInputChange: (event: InvoiceEvent) => void;
}

const ClientDetails: React.FC<ClientDetailsProps> = (props) => {
  const { invoiceData, handleInvoiceInputChange } = props;

  return (
    <table className="ClientDetails m-4 custom-table">
      <thead>
        <tr>
          <th colSpan={2}>CLIENT DETAILS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="uppercase">First Name</td>
          <td>
            <input
              type="text"
              name="clientFirstName"
              value={invoiceData.clientFirstName}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td className="uppercase">Middle Name</td>
          <td>
            <input
              type="text"
              name="clientMiddleName"
              value={invoiceData.clientMiddleName}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td className="uppercase">Last Name</td>
          <td>
            <input
              type="text"
              name="clientLastName"
              value={invoiceData.clientLastName}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>CONTACT #</td>
          <td>
            <input
              type="text"
              name="clientContactNumber"
              required
              value={invoiceData.clientContactNumber}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>EMAIL</td>
          <td>
            <input
              type="email"
              name="clientEmail"
              required
              value={invoiceData.clientEmail}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>STREET NAME</td>
          <td>
            <input
              type="text"
              name="clientAddressStreet"
              required
              value={invoiceData.clientAddressStreet}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>CITY</td>
          <td>
            <input
              type="text"
              name="clientAddressCity"
              required
              value={invoiceData.clientAddressCity}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>PROVINCE</td>
          <td>
            <select
              name="clientAddressProvince"
              onChange={handleInvoiceInputChange}
              value={invoiceData.clientAddressProvince}
            >
              {CANADA_PROVINCES.map((province) => (
                <option key={province} value={province}>
                  {province}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>POSTAL CODE</td>
          <td>
            <input
              type="text"
              name="clientAddressPostalCode"
              required
              pattern="^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$"
              value={invoiceData.clientAddressPostalCode}
              onInvalid={(e) =>
                (e.target as HTMLObjectElement).setCustomValidity(
                  'Please provide a valid postal code.'
                )
              }
              onChange={(e) => {
                e.target.setCustomValidity('');
                e.target.value = e.target.value.toUpperCase();
                handleInvoiceInputChange(e);
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ClientDetails;
