import React, { useState } from 'react';
import './styles/main.css';
import './App.css';
import Footer from './components/Footer';
import Routes from './Routes';
import Header from './components/Header';
import ContactBarContext from './context/ContactBarContext';

function App(): JSX.Element {
  const [isShowContactBar, setShowContactBar] = useState(false);
  return (
    <ContactBarContext.Provider value={{ isShowContactBar, setShowContactBar }}>
      <div
        data-testid="App"
        className="grid grid-rows-main h-screen w-screen text-primary"
      >
        <Header />
        {/* put flex class here because the div overrides the grid layout,
        and we need it for m-auto to center div element in the children */}
        <div className="overflow-auto flex">
          <Routes />
        </div>
        <Footer />
      </div>
    </ContactBarContext.Provider>
  );
}

export default App;
