import React from 'react';

import Logo from '../../images/logo.png';
import './Loading.css';

const Loading: React.FC = () => (
  <div className="Loading LoadingContainer" data-testid="Loading">
    <img className="logo" src={Logo} alt="logo" />
  </div>
);

export default Loading;
