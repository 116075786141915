import React, { useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import Modal from '../../components/Modal/Modal';
import Loading from '../../components/Loading';
import TableTitle from '../../components/TableTitle';
import {
  useStoreInvoiceInfoMutation,
  InvoiceInfoInput,
  useMonerisCreatePaymentMutation,
  Provider,
  PaymentMethod,
  CreatePaymentInput
} from '../../generated/graphql';
import getEnv from '../../utils/getEnv';
import './Invoice.css';
import ClientDetails from './ClientDetails';
import InvoiceDetails from './InvoiceDetails';
import { defaultInvoiceInfo, InvoiceInfoState } from './InvoiceData';
import { InvoiceEvent } from './type';
import PolicyDetails from './PolicyDetails/PolicyDetails';

const Invoice: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [error, setError] = useState<Error | undefined>(undefined);

  const [invoiceData, setInvoiceDate] = useState<InvoiceInfoState>(
    defaultInvoiceInfo
  );

  const { user } = useAuth0();
  const { email: brokerEmail, name: brokerName } = user;

  const { REACT_APP_MONERIS_ENDPOINT } = getEnv();

  const MONERIS_FORM_ID = 'moneris-preprocessing-form';
  const MONERIS_HPP_ID = 'moneris_hpp_id';
  const MONERIS_TICKET = 'moneris_ticket';

  const [monerisCreatePayment] = useMonerisCreatePaymentMutation();

  const closeModal = () => {
    setOpen(false);
  };

  const handleInvoiceInputChange = (event: InvoiceEvent) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement;
    setInvoiceDate({ ...invoiceData, [target.name]: target.value });
  };

  const [
    storeInvoiceInfoMutation,
    { loading: loadingStoreInvoice }
  ] = useStoreInvoiceInfoMutation();

  const getInputById = (id: string) => {
    const ret = document.getElementById(id) as HTMLInputElement;
    if (!ret) {
      throw new Error(`bug: input component not found, id: [${id}]`);
    }
    return ret;
  };

  const handleMonerisSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // Get Moneris tokens from the backend

    // Fill the tokens in a HTML form and use its submission mechanism
    // to starts a Post request to Moneris.

    const createPaymentInput: CreatePaymentInput = {
      invoiceId,
      method: PaymentMethod.CreditCard,
      provider: Provider.Moneris
    };
    monerisCreatePayment({
      variables: { paymentInfo: createPaymentInput }
    })
      .then(({ data: monerisCreatePaymentData }) => {
        const form = document.getElementById(
          MONERIS_FORM_ID
        ) as HTMLFormElement;
        if (!form) {
          throw new Error('bug: moneris preprocessing form not found');
        }
        if (monerisCreatePaymentData) {
          const {
            hpp_id,
            ticket
          } = monerisCreatePaymentData.monerisCreatePayment;
          getInputById(MONERIS_HPP_ID).value = hpp_id;
          getInputById(MONERIS_TICKET).value = ticket;
          form.submit();
        }
      })
      .catch(setError);
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      invoiceNumber,
      invoiceDate,
      quoteId,
      totalPremium,
      tax,

      clientFirstName,
      clientMiddleName,
      clientLastName,
      clientContactNumber,
      clientEmail,
      clientAddressStreet,
      clientAddressCity,
      clientAddressProvince,
      clientAddressPostalCode,

      policyDetailsEffectiveDate,
      policyDetailsDescription,
      policyDetailsNotes
    } = invoiceData;

    const invoiceInfo: InvoiceInfoInput = {
      invoiceNumber,
      invoiceDate: invoiceDate,
      quoteId,
      totalPremium: +totalPremium,
      tax: +tax,
      broker: {
        email: brokerEmail,
        name: brokerName
      },
      client: {
        name: {
          first: clientFirstName,
          middle: clientMiddleName,
          last: clientLastName
        },
        contactNumber: clientContactNumber,
        email: clientEmail,
        address: {
          street: clientAddressStreet,
          city: clientAddressCity,
          region: clientAddressProvince,
          postalCode: clientAddressPostalCode
        }
      },
      policyDetails: {
        effectiveDate: policyDetailsEffectiveDate,
        description: policyDetailsDescription.map(({ value }) => value),
        notes: policyDetailsNotes
      }
    };

    try {
      const {
        data: storeInvoiceInfoMutationData
      } = await storeInvoiceInfoMutation({
        variables: { invoice: invoiceInfo }
      });

      if (storeInvoiceInfoMutationData) {
        const invoiceObjectID = storeInvoiceInfoMutationData.storeInvoiceInfo;
        if (!invoiceObjectID) {
          throw new Error('invoiceId should be ready');
        }
        setInvoiceId(invoiceObjectID);
        setOpen(true);
      } else {
        throw new Error(
          'mutation response data for storeInvoiceInfo should be ready'
        );
      }
    } catch (e) {
      setError(e);
    }
  };

  if (loadingStoreInvoice) {
    return <Loading />;
  }

  if (error) {
    return (
      <Modal setClose={() => setError(undefined)} closeName="BACK">
        <p className="px-8 py-12">
          {(error && error.message) ||
            'Sorry, an unexpected error has occurred. Please check your answer or try again.'}
        </p>
      </Modal>
    );
  }

  const body = (
    <div className="px-8 py-12">
      <p>
        Moneris processes the payments for Foxquilt. After clicking NEXT you
        will be redirected to a secure payment page hosted by Moneris.
      </p>
      <p>Your information will not be shared with any third party.</p>
    </div>
  );
  if (isOpen) {
    return (
      <>
        <form
          id={MONERIS_FORM_ID}
          method="post"
          action={REACT_APP_MONERIS_ENDPOINT}
          className="hidden"
        >
          <input type="hidden" id={MONERIS_HPP_ID} name="hpp_id" />
          <input type="hidden" name="hpp_preload" value="" />
          <input type="hidden" id={MONERIS_TICKET} name="ticket" />
        </form>
        <Modal
          setClose={closeModal}
          onAction={handleMonerisSubmit}
          actionName={'NEXT'}
        >
          {body}
        </Modal>
      </>
    );
  }

  return (
    <div className="m-auto text-center">
      <form onSubmit={handleSubmitForm}>
        <div className="container m-auto">
          <TableTitle>Invoice Management</TableTitle>
          <div className="grid sm:grid-cols-2">
            <InvoiceDetails
              invoiceData={invoiceData}
              handleInvoiceInputChange={handleInvoiceInputChange}
            />
            <ClientDetails
              invoiceData={invoiceData}
              handleInvoiceInputChange={handleInvoiceInputChange}
            />
            {/* PolicyDetails will span 2 columns in the grid layout to fit the long policy descriptions */}
            <PolicyDetails
              invoiceData={invoiceData}
              handleInvoiceInputChange={handleInvoiceInputChange}
            />
          </div>
          <button
            type="submit"
            className="bg-tertiary py-2 px-6 text-base font-bold rounded-md mb-8"
          >
            INTIATE PAYMENT
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAuthenticationRequired(Invoice, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />
});
