import React from 'react';
import { PaymentMethod } from '../../../generated/graphql';
import FirstButton from './FirstButton/FirstButton';

interface ProceedButtonProps {
  invoiceId: string;
  openModel: () => void;
  setChequeOrDirectDeposit: () => void;
  paymentMethod: PaymentMethod;
}

const BUTTON_CLASS = 'bg-tertiary py-2 px-6 text-base font-bold rounded-md';
const BUTTON_LABEL = 'PROCEED';

const ProceedButton: React.FC<ProceedButtonProps> = ({
  invoiceId,
  paymentMethod,
  openModel,
  setChequeOrDirectDeposit
}) => {
  switch (paymentMethod) {
    case PaymentMethod.CreditCard: // fall-through
    case PaymentMethod.DebitCard: // fall-through
    case PaymentMethod.InteracOnline:
      return (
        <button
          className={BUTTON_CLASS}
          onClick={openModel}
          data-testid="ProceedButton"
        >
          {BUTTON_LABEL}
        </button>
      );
    case PaymentMethod.DirectDepositOrCheque:
      return (
        <button
          type="button"
          className={BUTTON_CLASS}
          data-testid="ProceedButton"
          onClick={setChequeOrDirectDeposit}
        >
          {BUTTON_LABEL}
        </button>
      );
    case PaymentMethod.Installment:
      return (
        <div data-testid="ProceedButton">
          <FirstButton invoiceId={invoiceId}>{BUTTON_LABEL}</FirstButton>
        </div>
      );
    default:
      throw new Error(
        `cannot create submit button because payment method is not supported: ${PaymentMethod[paymentMethod]}`
      );
  }
};

export default ProceedButton;
