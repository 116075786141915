import React from 'react';
import MultiSelect from 'react-multi-select-component';
import { InvoiceInfoState } from '../InvoiceData';
import { InvoiceEvent } from '../type';
import './PolicyDetails.css';

const POLICY_DESCRIPTION_OPTIONS = [
  ['CGL', 'Commercial General Liability (CGL)'],
  ['E&O', 'Professional Liability (E&O)'],
  ['D&O', 'Management Liability (D&O)'],
  ['Crime', 'Crime (Employee Dishonesty)'],
  ['Cyber Liability', 'Cyber Liability'],
  ['Legal Expense', 'Legal Expense'],
  ['Medical Malpractice', 'Medical Malpractice']
].map(([value, label]) => ({
  label,
  value
}));

interface PolicyDetailsProps {
  invoiceData: InvoiceInfoState;
  handleInvoiceInputChange: (event: InvoiceEvent) => void;
}

const PolicyDetails: React.FC<PolicyDetailsProps> = ({
  invoiceData,
  handleInvoiceInputChange
}) => (
  <div className="PolicyDetails m-4 sm:col-span-2">
    <table
      className="custom-table w-full sm:w-auto"
      data-testid="PolicyDetails"
    >
      <thead>
        <tr>
          <th colSpan={2}>POLICY DETAILS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>EFFECTIVE DATE</td>
          <td>
            <input
              type="date"
              name="policyDetailsEffectiveDate"
              required
              value={invoiceData.policyDetailsEffectiveDate}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>POLICY DESCRIPTION</td>
          <td>
            <MultiSelect
              options={POLICY_DESCRIPTION_OPTIONS}
              value={invoiceData.policyDetailsDescription}
              onChange={(selectedValues: unknown) => {
                handleInvoiceInputChange({
                  target: {
                    name: 'policyDetailsDescription',
                    value: selectedValues
                  }
                });
              }}
              labelledBy={'Select'}
            ></MultiSelect>
          </td>
        </tr>
        <tr>
          <td>NOTES</td>
          <td>
            <textarea
              rows={3}
              cols={30}
              className="w-full"
              name="policyDetailsNotes"
              value={invoiceData.policyDetailsNotes}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default PolicyDetails;
