import React, { useState } from 'react';

import { useFirstGenerateSigningUrlMutation } from '../../generated/graphql';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal/Modal';

type FirstRedirectModalProps = {
  setClose: () => void;
  quoteId: number;
};

const FirstRedirectModal: React.FC<FirstRedirectModalProps> = (props) => {
  const { quoteId, setClose } = props;
  const [isRedirectToFirst, setRedirectToFirst] = useState(false);

  const [
    firstGenerateSigningUrl,
    {
      loading: loadingFirstGenerateSigningUrl,
      error: firstGenerateSigningUrlError
    }
  ] = useFirstGenerateSigningUrlMutation();

  const onAction = async () => {
    setRedirectToFirst(true);
    const { data: signURLData } = await firstGenerateSigningUrl({
      variables: { quoteId }
    });
    if (!signURLData) {
      throw new Error('first sign url data should be ready');
    }
    window.location.replace(signURLData.firstGenerateSigningURL);
  };

  if (loadingFirstGenerateSigningUrl || isRedirectToFirst) {
    return <Loading />;
  }

  if (firstGenerateSigningUrlError) {
    return (
      <Modal>
        <p className="px-8 py-12">Sorry, an unexpected error has occurred.</p>
      </Modal>
    );
  }

  const body = (
    <p className="px-8 py-12">
      You will be redirected to FIRST INSURANCE FUNDING&#39;s page to accept and
      sign the Premium Finance Agreement.
    </p>
  );
  return (
    <Modal setClose={setClose} onAction={onAction}>
      {body}
    </Modal>
  );
};

export default FirstRedirectModal;
