import { useEffect, useContext } from 'react';
import ContactBarContext from '../../context/ContactBarContext';

export const useContactBar = (): void => {
  const { setShowContactBar } = useContext(ContactBarContext);

  useEffect(() => {
    // Should trigger this when this page is mounted
    setShowContactBar(true);

    // Should trigger this when this page will be unmounted
    return () => {
      setShowContactBar(false);
    };
  }, [setShowContactBar]);
};
