import React from 'react';
import { useContactBar } from '../../utils/hooks/useContactBarHook';
import './OfflinePayment.css';

const OfflinePayment: React.FC = () => {
  useContactBar();

  return (
    <div className="OfflinePayment m-auto text-lg" data-testid="OfflinePayment">
      Thank you! Your broker will reach out to you within 1 business day to
      explain the next steps.
    </div>
  );
};

export default OfflinePayment;
