import React, { useState } from 'react';

import { useFirstImportQuoteQuery } from '../../generated/graphql';
import Loading from '../../components/Loading';
import PaymentDetails from './PaymentDetails';
import InstallmentDetails from './InstallmentDetails';
import { useParams } from 'react-router-dom';
import FirstRedirectModal from './FirstRedirectModal';

const BUTTON_CLASS =
  'bg-tertiary py-2 px-6 text-base font-bold rounded-md uppercase';

const Installment: React.FC = () => {
  interface InitiatePaymentParams {
    paymentId: string;
  }
  const { paymentId } = useParams<InitiatePaymentParams>();
  const {
    loading: isLoadingFirstImportQuote,
    error: firstImportQuoteError,
    data: firstImportQuoteData,
    refetch: refetchFirstImportQuote
  } = useFirstImportQuoteQuery({
    variables: { paymentId: paymentId }
  });
  const [isOpen, setOpen] = useState(false);

  const OpenModal = () => {
    setOpen(true);
  };

  const setClose = () => {
    setOpen(false);
  };

  if (isLoadingFirstImportQuote) {
    return <Loading />;
  }

  if (firstImportQuoteError) {
    return (
      <div className="m-auto">
        <div className="p-12 bg-white border border-solid rounded-lg">
          An unexpected error has occurred, please try again.
          <button
            type="button"
            className={`${BUTTON_CLASS} block mt-6`}
            onClick={() => refetchFirstImportQuote()}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!firstImportQuoteData) {
    throw new Error('first data should be ready');
  }

  const paymentInfo = firstImportQuoteData.firstImportQuote;
  if (isOpen) {
    const { quoteId } = paymentInfo;
    if (!quoteId) {
      throw new Error('quote id is not defined');
    }
    return <FirstRedirectModal quoteId={quoteId} setClose={setClose} />;
  }

  return (
    <form className="m-auto text-center">
      <div className="uppercase text-left p-2 text-2xl bg-secondary rounded-md border-none font-bold">
        Easy Monthly Payments
      </div>
      <div className="grid sm:grid-cols-2">
        <PaymentDetails paymentInfo={paymentInfo} />
        <InstallmentDetails paymentInfo={paymentInfo} />
      </div>
      <div className="m-2">
        <button type="button" className={BUTTON_CLASS} onClick={OpenModal}>
          Setup Installments
        </button>
      </div>
    </form>
  );
};

export default Installment;
