import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CallLogo from '../../images/call.png';
import FoxquiltLogo from '../../images/logo-color.png';
import UserSetting from './UserSetting';

const Header: React.FC = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  return (
    <header
      className="Header grid grid-cols-2 grid-rows-header"
      data-testid="Header"
    >
      <div className="col-span-2 bg-primary py-3 text-center">
        <img className="w-8 inline-block" src={CallLogo} alt="call-foxquilt" />
        <a className="text-white text-lg" href="tel:+1-888-878-4588">
          1-888-878-4588
        </a>
      </div>
      <img className="w-20 p-2" src={FoxquiltLogo} alt="foxquilt-logo" />
      {isAuthenticated ? (
        <UserSetting name={user.given_name} logout={logout} />
      ) : null}
    </header>
  );
};

export default Header;
