import React, { useState } from 'react';
import InvoiceDetails from './InvoiceDetails/InvoiceDetails';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import ProceedButton from './ProceedButton/ProceedButton';
import {
  GetInvoiceErrorKind,
  PaymentMethod,
  useInitiatePaymentGetInvoiceQuery,
  useSetChequeOrDirectDepositMutation,
  ChequeOrDirectDepositInput
} from '../../generated/graphql';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import MonerisRedirectModal from './MonerisRedirectModal/MonerisRedirectModal';

interface InitiatePaymentParams {
  invoiceId: string;
}

const InitiatePayment: React.FC = () => {
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CreditCard
  );
  const [isOpenModal, setOpenModel] = useState(false);
  const { invoiceId } = useParams<InitiatePaymentParams>();
  const {
    data,
    error: getInvoiceError,
    loading: isGetInvoiceLoading
  } = useInitiatePaymentGetInvoiceQuery({
    variables: { invoiceId }
  });
  const [
    setChequeOrDirectDepositMutation,
    {
      loading: isSetChequeOrDirectDepositLoading,
      error: setChequeOrDirectDepositError
    }
  ] = useSetChequeOrDirectDepositMutation();

  if (isGetInvoiceLoading || isSetChequeOrDirectDepositLoading) {
    return <Loading />;
  }

  if (getInvoiceError || !data?.getInvoice || setChequeOrDirectDepositError) {
    return <Redirect to="/404" />;
  }

  switch (data?.getInvoice.__typename) {
    case 'InvoiceInfo':
      break; // valid value
    case 'GetInvoiceError':
      switch (data.getInvoice.kind) {
        case GetInvoiceErrorKind.NotFound:
          return <Redirect to="/404" />;
        case GetInvoiceErrorKind.Paid:
          return <Redirect to="/payment-success" />;
        default:
          throw new Error('unreachable');
      }
    default:
      return <Redirect to="/404" />;
  }

  const invoiceData = data.getInvoice;

  const setChequeOrDirectDeposit = async () => {
    if (data) {
      const invoiceInfo: ChequeOrDirectDepositInput = {
        invoiceNumber: invoiceData.invoiceNumber
      };
      await setChequeOrDirectDepositMutation({
        variables: {
          invoiceInfo
        }
      });
      history.push('/offline-payment');
    }
  };

  if (isOpenModal) {
    return (
      <MonerisRedirectModal
        invoiceId={invoiceId}
        setClose={() => setOpenModel(false)}
      />
    );
  }

  return (
    <div
      className="InitiatePayment m-auto text:lg sm:text-xl"
      data-testid="InitiatePayment"
    >
      <div className="container">
        <div className="uppercase text-left p-2 text-2xl bg-secondary rounded-md border-none font-bold">
          Complete Payment
        </div>
        <div className="text-xl my-4">
          Please choose a payment option and complete your payment
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1">
          <div className="col-span-1 uppercase text-left p-2 text-lg bg-gray-400 rounded-md border-none font-bold mb-4">
            Billing Information
          </div>
          <div className="sm:row-start-2">
            <InvoiceDetails
              invoiceData={invoiceData}
              paymentMethod={paymentMethod}
            />
          </div>
          <div className="sm:row-start-2">
            <PaymentOptions
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>
        </div>
        {paymentMethod !== undefined ? (
          <div className="text-center m-4">
            <ProceedButton
              invoiceId={invoiceId}
              paymentMethod={paymentMethod}
              openModel={() => setOpenModel(true)}
              setChequeOrDirectDeposit={setChequeOrDirectDeposit}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InitiatePayment;
