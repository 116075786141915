import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFirstCreatePaymentMutation } from '../../../../generated/graphql';
import './FirstButton.css';

const BUTTON_CLASS = 'bg-tertiary py-2 px-6 text-base font-bold rounded-md';

interface FirstButtonProps {
  invoiceId: string;
}

const FirstButton: React.FC<FirstButtonProps> = ({ children, invoiceId }) => {
  const [firstCreatePayment] = useFirstCreatePaymentMutation();
  const history = useHistory();

  const handleClick = () => {
    firstCreatePayment({ variables: { invoiceId } })
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const paymentId = data.firstCreatePayment;
        history.push(`/installment/${paymentId}`);
      })
      .catch((e) => {
        //TODO log the exception to sentry
        throw e;
      });
  };

  return (
    <button
      className={`FirstButton ${BUTTON_CLASS}`}
      data-testid="FirstButton"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default FirstButton;
