import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** This is a string type with the constrain that the values can be one of: Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon */
  CanadaProvince: "Alberta" | "British Columbia" | "Manitoba" | "New Brunswick" | "Newfoundland and Labrador" | "Northwest Territories" | "Nova Scotia" | "Nunavut" | "Ontario" | "Prince Edward Island" | "Quebec" | "Saskatchewan" | "Yukon";
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: string;
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: number;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  ObjectID: string;
  /** This is a string type with the constrain that the values can be one of: CGL,E&O,D&O,Crime,Cyber Liability,Legal Expense,Medical Malpractice */
  PolicyDescription: "CGL" | "E&O" | "D&O" | "Crime" | "Cyber Liability" | "Legal Expense" | "Medical Malpractice";
  /** Floats that will have a value greater than 0. */
  PositiveFloat: number;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: string;
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type NameInput = {
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};

export type FirstPaymentInfo = {
  __typename?: 'FirstPaymentInfo';
  quoteId: Scalars['NonNegativeInt'];
  installmentStartDate: Scalars['Date'];
  totalPremium: Scalars['NonNegativeFloat'];
  downPayment: Scalars['NonNegativeFloat'];
  amountFinanced: Scalars['NonNegativeFloat'];
  numberOfInstallments: Scalars['NonNegativeFloat'];
  installmentAmount: Scalars['NonNegativeFloat'];
  totalPayments: Scalars['NonNegativeFloat'];
  financeCharge: Scalars['NonNegativeFloat'];
  apr: Scalars['NonNegativeFloat'];
};

export type BrokerInput = {
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
};

export type InvoiceClientInput = {
  name: NameInput;
  contactNumber: Scalars['String'];
  email: Scalars['EmailAddress'];
  address: AddressInput;
};

export type InvoicePolicyDetailsInput = {
  effectiveDate: Scalars['Date'];
  description: Array<Scalars['PolicyDescription']>;
  notes: Scalars['String'];
};

export type InvoiceInfoInput = {
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteId: Scalars['String'];
  totalPremium: Scalars['NonNegativeFloat'];
  tax: Scalars['NonNegativeFloat'];
  broker: BrokerInput;
  client: InvoiceClientInput;
  policyDetails: InvoicePolicyDetailsInput;
};

export type InvoiceInfo = {
  __typename?: 'InvoiceInfo';
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteId: Scalars['String'];
  totalPremium: Scalars['NonNegativeFloat'];
  tax: Scalars['NonNegativeFloat'];
};

export enum GetInvoiceErrorKind {
  /** This means the customer has made a payment. Instead of revealing the data we just return a flag. */
  Paid = 'Paid',
  /** This means invoiceId doesn't match our database. Record doesn't exist. */
  NotFound = 'NotFound'
}

export type GetInvoiceError = {
  __typename?: 'GetInvoiceError';
  kind: GetInvoiceErrorKind;
};

export type GetInvoiceResult = InvoiceInfo | GetInvoiceError;

export type MonerisPreloadData = {
  __typename?: 'MonerisPreloadData';
  hpp_id: Scalars['String'];
  ticket: Scalars['String'];
};

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  DebitCard = 'DebitCard',
  InteracOnline = 'InteracOnline',
  DirectDepositOrCheque = 'DirectDepositOrCheque',
  Installment = 'Installment'
}

export enum Provider {
  Moneris = 'Moneris',
  First = 'First'
}

export type CreatePaymentInput = {
  invoiceId: Scalars['ObjectID'];
  method: PaymentMethod;
  provider: Provider;
};

export type ChequeOrDirectDepositInput = {
  invoiceNumber: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  noOp?: Maybe<Scalars['Boolean']>;
  firstImportQuote: FirstPaymentInfo;
  getInvoice: GetInvoiceResult;
  isInvoiceNumberRegistered: Scalars['Boolean'];
};


export type QueryFirstImportQuoteArgs = {
  paymentId: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type QueryIsInvoiceNumberRegisteredArgs = {
  invoiceNumber: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  noOp?: Maybe<Scalars['Boolean']>;
  firstCreatePayment: Scalars['ObjectID'];
  firstGenerateSigningURL: Scalars['String'];
  storeInvoiceInfo: Scalars['ObjectID'];
  monerisCreatePayment: MonerisPreloadData;
  setChequeOrDirectDeposit: Scalars['String'];
};


export type MutationFirstCreatePaymentArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type MutationFirstGenerateSigningUrlArgs = {
  quoteId: Scalars['NonNegativeInt'];
};


export type MutationStoreInvoiceInfoArgs = {
  invoice: InvoiceInfoInput;
};


export type MutationMonerisCreatePaymentArgs = {
  paymentInfo: CreatePaymentInput;
};


export type MutationSetChequeOrDirectDepositArgs = {
  invoiceInfo: ChequeOrDirectDepositInput;
};










export type FirstCreatePaymentMutationVariables = Exact<{
  invoiceId: Scalars['ObjectID'];
}>;


export type FirstCreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'firstCreatePayment'>
);

export type FirstGenerateSigningUrlMutationVariables = Exact<{
  quoteId: Scalars['NonNegativeInt'];
}>;


export type FirstGenerateSigningUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'firstGenerateSigningURL'>
);

export type FirstImportQuoteQueryVariables = Exact<{
  paymentId: Scalars['String'];
}>;


export type FirstImportQuoteQuery = (
  { __typename?: 'Query' }
  & { firstImportQuote: (
    { __typename?: 'FirstPaymentInfo' }
    & Pick<FirstPaymentInfo, 'quoteId' | 'totalPremium' | 'downPayment' | 'amountFinanced' | 'numberOfInstallments' | 'installmentStartDate' | 'installmentAmount' | 'totalPayments' | 'financeCharge' | 'apr'>
  ) }
);

export type MonerisCreatePaymentMutationVariables = Exact<{
  paymentInfo: CreatePaymentInput;
}>;


export type MonerisCreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { monerisCreatePayment: (
    { __typename?: 'MonerisPreloadData' }
    & Pick<MonerisPreloadData, 'hpp_id' | 'ticket'>
  ) }
);

export type SetChequeOrDirectDepositMutationVariables = Exact<{
  invoiceInfo: ChequeOrDirectDepositInput;
}>;


export type SetChequeOrDirectDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setChequeOrDirectDeposit'>
);

export type StoreInvoiceInfoMutationVariables = Exact<{
  invoice: InvoiceInfoInput;
}>;


export type StoreInvoiceInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'storeInvoiceInfo'>
);

export type InitiatePaymentGetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ObjectID'];
}>;


export type InitiatePaymentGetInvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice: (
    { __typename: 'InvoiceInfo' }
    & Pick<InvoiceInfo, 'invoiceDate' | 'invoiceNumber' | 'quoteId' | 'tax' | 'totalPremium'>
  ) | (
    { __typename: 'GetInvoiceError' }
    & Pick<GetInvoiceError, 'kind'>
  ) }
);

export type IsInvoiceNumberRegisteredQueryVariables = Exact<{
  invoiceNumber: Scalars['String'];
}>;


export type IsInvoiceNumberRegisteredQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isInvoiceNumberRegistered'>
);


export const FirstCreatePaymentDocument = gql`
    mutation firstCreatePayment($invoiceId: ObjectID!) {
  firstCreatePayment(invoiceId: $invoiceId)
}
    `;
export type FirstCreatePaymentMutationFn = ApolloReactCommon.MutationFunction<FirstCreatePaymentMutation, FirstCreatePaymentMutationVariables>;

/**
 * __useFirstCreatePaymentMutation__
 *
 * To run a mutation, you first call `useFirstCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirstCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firstCreatePaymentMutation, { data, loading, error }] = useFirstCreatePaymentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useFirstCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FirstCreatePaymentMutation, FirstCreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<FirstCreatePaymentMutation, FirstCreatePaymentMutationVariables>(FirstCreatePaymentDocument, baseOptions);
      }
export type FirstCreatePaymentMutationHookResult = ReturnType<typeof useFirstCreatePaymentMutation>;
export type FirstCreatePaymentMutationResult = ApolloReactCommon.MutationResult<FirstCreatePaymentMutation>;
export type FirstCreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<FirstCreatePaymentMutation, FirstCreatePaymentMutationVariables>;
export const FirstGenerateSigningUrlDocument = gql`
    mutation firstGenerateSigningURL($quoteId: NonNegativeInt!) {
  firstGenerateSigningURL(quoteId: $quoteId)
}
    `;
export type FirstGenerateSigningUrlMutationFn = ApolloReactCommon.MutationFunction<FirstGenerateSigningUrlMutation, FirstGenerateSigningUrlMutationVariables>;

/**
 * __useFirstGenerateSigningUrlMutation__
 *
 * To run a mutation, you first call `useFirstGenerateSigningUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirstGenerateSigningUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firstGenerateSigningUrlMutation, { data, loading, error }] = useFirstGenerateSigningUrlMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useFirstGenerateSigningUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FirstGenerateSigningUrlMutation, FirstGenerateSigningUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<FirstGenerateSigningUrlMutation, FirstGenerateSigningUrlMutationVariables>(FirstGenerateSigningUrlDocument, baseOptions);
      }
export type FirstGenerateSigningUrlMutationHookResult = ReturnType<typeof useFirstGenerateSigningUrlMutation>;
export type FirstGenerateSigningUrlMutationResult = ApolloReactCommon.MutationResult<FirstGenerateSigningUrlMutation>;
export type FirstGenerateSigningUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<FirstGenerateSigningUrlMutation, FirstGenerateSigningUrlMutationVariables>;
export const FirstImportQuoteDocument = gql`
    query firstImportQuote($paymentId: String!) {
  firstImportQuote(paymentId: $paymentId) {
    quoteId
    totalPremium
    downPayment
    amountFinanced
    numberOfInstallments
    installmentStartDate
    installmentAmount
    totalPayments
    financeCharge
    apr
  }
}
    `;

/**
 * __useFirstImportQuoteQuery__
 *
 * To run a query within a React component, call `useFirstImportQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstImportQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstImportQuoteQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useFirstImportQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FirstImportQuoteQuery, FirstImportQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<FirstImportQuoteQuery, FirstImportQuoteQueryVariables>(FirstImportQuoteDocument, baseOptions);
      }
export function useFirstImportQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FirstImportQuoteQuery, FirstImportQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FirstImportQuoteQuery, FirstImportQuoteQueryVariables>(FirstImportQuoteDocument, baseOptions);
        }
export type FirstImportQuoteQueryHookResult = ReturnType<typeof useFirstImportQuoteQuery>;
export type FirstImportQuoteLazyQueryHookResult = ReturnType<typeof useFirstImportQuoteLazyQuery>;
export type FirstImportQuoteQueryResult = ApolloReactCommon.QueryResult<FirstImportQuoteQuery, FirstImportQuoteQueryVariables>;
export const MonerisCreatePaymentDocument = gql`
    mutation monerisCreatePayment($paymentInfo: CreatePaymentInput!) {
  monerisCreatePayment(paymentInfo: $paymentInfo) {
    hpp_id
    ticket
  }
}
    `;
export type MonerisCreatePaymentMutationFn = ApolloReactCommon.MutationFunction<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>;

/**
 * __useMonerisCreatePaymentMutation__
 *
 * To run a mutation, you first call `useMonerisCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonerisCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monerisCreatePaymentMutation, { data, loading, error }] = useMonerisCreatePaymentMutation({
 *   variables: {
 *      paymentInfo: // value for 'paymentInfo'
 *   },
 * });
 */
export function useMonerisCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>(MonerisCreatePaymentDocument, baseOptions);
      }
export type MonerisCreatePaymentMutationHookResult = ReturnType<typeof useMonerisCreatePaymentMutation>;
export type MonerisCreatePaymentMutationResult = ApolloReactCommon.MutationResult<MonerisCreatePaymentMutation>;
export type MonerisCreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>;
export const SetChequeOrDirectDepositDocument = gql`
    mutation SetChequeOrDirectDeposit($invoiceInfo: ChequeOrDirectDepositInput!) {
  setChequeOrDirectDeposit(invoiceInfo: $invoiceInfo)
}
    `;
export type SetChequeOrDirectDepositMutationFn = ApolloReactCommon.MutationFunction<SetChequeOrDirectDepositMutation, SetChequeOrDirectDepositMutationVariables>;

/**
 * __useSetChequeOrDirectDepositMutation__
 *
 * To run a mutation, you first call `useSetChequeOrDirectDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChequeOrDirectDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChequeOrDirectDepositMutation, { data, loading, error }] = useSetChequeOrDirectDepositMutation({
 *   variables: {
 *      invoiceInfo: // value for 'invoiceInfo'
 *   },
 * });
 */
export function useSetChequeOrDirectDepositMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetChequeOrDirectDepositMutation, SetChequeOrDirectDepositMutationVariables>) {
        return ApolloReactHooks.useMutation<SetChequeOrDirectDepositMutation, SetChequeOrDirectDepositMutationVariables>(SetChequeOrDirectDepositDocument, baseOptions);
      }
export type SetChequeOrDirectDepositMutationHookResult = ReturnType<typeof useSetChequeOrDirectDepositMutation>;
export type SetChequeOrDirectDepositMutationResult = ApolloReactCommon.MutationResult<SetChequeOrDirectDepositMutation>;
export type SetChequeOrDirectDepositMutationOptions = ApolloReactCommon.BaseMutationOptions<SetChequeOrDirectDepositMutation, SetChequeOrDirectDepositMutationVariables>;
export const StoreInvoiceInfoDocument = gql`
    mutation storeInvoiceInfo($invoice: InvoiceInfoInput!) {
  storeInvoiceInfo(invoice: $invoice)
}
    `;
export type StoreInvoiceInfoMutationFn = ApolloReactCommon.MutationFunction<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>;

/**
 * __useStoreInvoiceInfoMutation__
 *
 * To run a mutation, you first call `useStoreInvoiceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreInvoiceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeInvoiceInfoMutation, { data, loading, error }] = useStoreInvoiceInfoMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useStoreInvoiceInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>(StoreInvoiceInfoDocument, baseOptions);
      }
export type StoreInvoiceInfoMutationHookResult = ReturnType<typeof useStoreInvoiceInfoMutation>;
export type StoreInvoiceInfoMutationResult = ApolloReactCommon.MutationResult<StoreInvoiceInfoMutation>;
export type StoreInvoiceInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>;
export const InitiatePaymentGetInvoiceDocument = gql`
    query initiatePaymentGetInvoice($invoiceId: ObjectID!) {
  getInvoice(invoiceId: $invoiceId) {
    __typename
    ... on InvoiceInfo {
      invoiceDate
      invoiceNumber
      quoteId
      tax
      totalPremium
    }
    ... on GetInvoiceError {
      kind
    }
  }
}
    `;

/**
 * __useInitiatePaymentGetInvoiceQuery__
 *
 * To run a query within a React component, call `useInitiatePaymentGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiatePaymentGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiatePaymentGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInitiatePaymentGetInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>(InitiatePaymentGetInvoiceDocument, baseOptions);
      }
export function useInitiatePaymentGetInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>(InitiatePaymentGetInvoiceDocument, baseOptions);
        }
export type InitiatePaymentGetInvoiceQueryHookResult = ReturnType<typeof useInitiatePaymentGetInvoiceQuery>;
export type InitiatePaymentGetInvoiceLazyQueryHookResult = ReturnType<typeof useInitiatePaymentGetInvoiceLazyQuery>;
export type InitiatePaymentGetInvoiceQueryResult = ApolloReactCommon.QueryResult<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>;
export const IsInvoiceNumberRegisteredDocument = gql`
    query isInvoiceNumberRegistered($invoiceNumber: String!) {
  isInvoiceNumberRegistered(invoiceNumber: $invoiceNumber)
}
    `;

/**
 * __useIsInvoiceNumberRegisteredQuery__
 *
 * To run a query within a React component, call `useIsInvoiceNumberRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsInvoiceNumberRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsInvoiceNumberRegisteredQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useIsInvoiceNumberRegisteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>) {
        return ApolloReactHooks.useQuery<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>(IsInvoiceNumberRegisteredDocument, baseOptions);
      }
export function useIsInvoiceNumberRegisteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>(IsInvoiceNumberRegisteredDocument, baseOptions);
        }
export type IsInvoiceNumberRegisteredQueryHookResult = ReturnType<typeof useIsInvoiceNumberRegisteredQuery>;
export type IsInvoiceNumberRegisteredLazyQueryHookResult = ReturnType<typeof useIsInvoiceNumberRegisteredLazyQuery>;
export type IsInvoiceNumberRegisteredQueryResult = ApolloReactCommon.QueryResult<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>;