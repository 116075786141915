import React from 'react';

interface ModalProps {
  setClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeName?: string;
  actionName?: string;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { setClose, onAction, actionName, closeName } = props;

  const closeButton = setClose ? (
    <button
      onClick={setClose}
      className="px-12 py-3 text-base font-bold text-white bg-gray-500 rounded-md"
    >
      {closeName || 'CANCEL'}
    </button>
  ) : null;

  const actionButton = onAction ? (
    <button
      onClick={onAction}
      className="px-12 py-3 text-base font-bold text-white rounded-md py-43 bg-secondary"
    >
      {actionName || 'NEXT'}
    </button>
  ) : null;

  return (
    <div
      className="flex flex-col w-full min-h-full transition duration-200 ease-in-out bg-black bg-opacity-25"
      data-testid="Modal"
    >
      <div className="box-content w-3/4 pt-6 pb-3 m-auto text-center bg-white border border-gray-600 border-solid md:max-w-3xl font-base">
        {props.children}
        <div className="flex flex-col flex-col-reverse gap-6 sm:flex-row justify-center mb-1">
          <div>{closeButton}</div>
          <div>{actionButton}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
