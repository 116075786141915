const STRING_BASE = [
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_GRAPHQL_URL',
  'REACT_APP_MONERIS_ENDPOINT'
] as const;

type Keys = typeof STRING_BASE[number];

export type Env = { [index in Keys]: string };

// runtime check during bootstrap
STRING_BASE.forEach((key) => {
  const val = process.env[key];
  if (!val) {
    throw new Error(`Environment variable isn't defined, ${key}`);
  }
});

export default function (): Env {
  // same signature, but defined fields are casted to non-nullable version
  return (process.env as unknown) as Env;
}
