import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Invoice from './pages/Invoice';
import PaymentFailure from './pages/PaymentFailure/PaymentFailure';
import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
import NotFound from './pages/NotFound/NotFound';
import OfflinePayment from './pages/OfflinePayment/OfflinePayment';
import InitiatePayment from './pages/InitiatePayment/InitiatePayment';
import Installment from './pages/Installment';

export default function Routes(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/installment/:paymentId" component={Installment} />
        <Route exact path="/invoice" component={Invoice} />
        <Route exact path="/payment-failure" component={PaymentFailure} />
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route exact path="/offline-payment" component={OfflinePayment} />
        <Route exact path="/payment/:invoiceId" component={InitiatePayment} />
        <Route exact path="/" component={Login} />
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
}
