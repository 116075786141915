import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink
} from '@apollo/client';
import { onError } from '@apollo/link-error';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import getEnv from './utils/getEnv';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_GRAPHQL_URL
} = getEnv();

const errorLink = onError((/* { graphQLErrors, networkError } */) => {
  //TODO log the error to Sentry
});

const httpLink = new HttpLink({ uri: REACT_APP_GRAPHQL_URL });

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN || ''}
      clientId={REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={window.location.origin}
    >
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
