import React, { Dispatch, SetStateAction } from 'react';

interface Context {
  isShowContactBar: boolean;
  setShowContactBar: Dispatch<SetStateAction<boolean>>;
}

const initialContext: Context = {
  isShowContactBar: false,
  setShowContactBar: (): void => {
    throw new Error('setShowContactBar function must be overridden');
  }
};
const ContactBarContext = React.createContext(initialContext);

export default ContactBarContext;
