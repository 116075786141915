import { PaymentMethod } from '../generated/graphql';

interface CalTotalPayableInvoiceParams {
  totalPremium: number;
  tax: number;
}

/**
 * Calculate total payable and credit card fees (if applicable).
 * note: Unlike the backend where Graphql guarantees input to be positive, the frontend
 * needs to do the check before calling this method.
 * @param param0.totalPremium non-negative number
 * @param param0.tax non-negative number
 * @param method the payment method
 */
const calTotalPayable = (
  { totalPremium, tax }: CalTotalPayableInvoiceParams,
  method: PaymentMethod
): { creditCardFee: number; totalPayable: number } => {
  const isCreditCard = method === PaymentMethod.CreditCard;
  const CREDIT_CARD_FEES_RATE = 0.025; // 2.5%
  const creditCardFee = isCreditCard
    ? +(totalPremium * CREDIT_CARD_FEES_RATE).toFixed(2)
    : 0;
  const totalPayable = +(totalPremium + tax + creditCardFee).toFixed(2);
  return { creditCardFee, totalPayable };
};

export default calTotalPayable;
