import React, { useState } from 'react';
import { Auth0ContextInterface } from '@auth0/auth0-react';

interface IUserSettingProps {
  name: string;
  logout: Auth0ContextInterface['logout'];
}

const UserSetting: React.FC<IUserSettingProps> = ({ name, logout }) => {
  const [isShowLogoutSetting, setShowLogoutSetting] = useState(false);

  return (
    <div
      className="text-right p-2 cursor-pointer font-bold uppercase"
      onClick={() => setShowLogoutSetting(!isShowLogoutSetting)}
    >
      Welcome {name} &#x25BC;
      {isShowLogoutSetting ? (
        <div>
          <button
            className="font-bold pl-4 pr-4"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            LOG OUT
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default UserSetting;
