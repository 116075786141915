import React from 'react';
import { FirstPaymentInfo } from '../../generated/graphql';

interface InstallmentDetails {
  paymentInfo: FirstPaymentInfo;
}

function calFlatRate(financeCharge: number, totalPremium: number): string {
  return ((financeCharge / totalPremium) * 100).toFixed(2);
}
const InstallmentDetails: React.FC<InstallmentDetails> = ({
  paymentInfo: {
    totalPremium,
    installmentStartDate,
    installmentAmount,
    totalPayments,
    financeCharge,
    apr
  }
}) => (
  <table className="m-4 custom-table">
    <tbody>
      <tr>
        <th colSpan={2}>INSTALLMENT DETAILS</th>
      </tr>
      <tr>
        <td>INSTALLMENT START DATE</td>
        <td>{installmentStartDate}</td>
      </tr>
      <tr>
        <td>INSTALLMENT AMOUNT</td>
        <td>{installmentAmount}</td>
      </tr>
      <tr>
        <td>TOTAL PAYMENTS</td>
        <td>{totalPayments}</td>
      </tr>
      <tr>
        <td>FINANCE CHARGE</td>
        <td>{financeCharge}</td>
      </tr>
      <tr>
        <td>APR</td>
        <td>{apr} </td>
      </tr>
      <tr>
        <td>FLAT RATE</td>
        <td>{calFlatRate(financeCharge, totalPremium)}</td>
      </tr>
    </tbody>
  </table>
);

export default InstallmentDetails;
