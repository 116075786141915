import React from 'react';
import amexImg from '../images/amex.png';
import interacImg from '../images/interac.png';
import mastercardImg from '../images/mastercard.png';
import visaImg from '../images/visa.jpg';
import './PaymentOptions.css';
import { OptionRadio, PayNowRadio } from './Radio/Radio';
import { PaymentMethod } from '../../../generated/graphql';

function isPayNow(method?: PaymentMethod): boolean {
  return (
    method === PaymentMethod.CreditCard ||
    method === PaymentMethod.DebitCard ||
    method === PaymentMethod.InteracOnline
  );
}

interface PaymentOptionsProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: (method: PaymentMethod) => void;
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  paymentMethod,
  setPaymentMethod
}) => {
  // in this component these are 2 different kinds of radio groups that share the same range of values (PaymentMethod),
  // so only one event handler is needed to handle them.
  const handlePaymentOptionChange = (e: React.FormEvent<HTMLDivElement>) => {
    const method: PaymentMethod =
      PaymentMethod[
        (e.target as HTMLInputElement).value as keyof typeof PaymentMethod
      ];
    if (PaymentMethod[method] === undefined) {
      throw new Error(
        'bug: payment method cannot be retrieved from radio buttons'
      );
    }
    setPaymentMethod(method);
  };

  return (
    <div
      className="PaymentOptions m-2"
      data-testid="PaymentOptions"
      onChange={handlePaymentOptionChange}
    >
      <table className="payment-options">
        <thead>
          <tr>
            <th className="uppercase text-left" colSpan={2}>
              Payment Options
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <OptionRadio value={PaymentMethod.CreditCard} defaultChecked />
            </td>
            <td>Pay Now</td>
          </tr>
          {isPayNow(paymentMethod) ? (
            <tr>
              <td />
              <td>
                <div>
                  <div className="m-1 flex flex-wrap">
                    <div className="pr-3">
                      <PayNowRadio
                        value={PaymentMethod.CreditCard}
                        defaultChecked
                      />{' '}
                      Credit Card
                    </div>
                    <div className="pr-3">
                      <PayNowRadio value={PaymentMethod.InteracOnline} />{' '}
                      Interac Online
                    </div>
                  </div>
                  <div className="mt-2 uppercase">We accept:</div>
                  <div className="flex flex-wrap logo-container">
                    <img src={visaImg} alt="Visa" />
                    <img src={mastercardImg} alt="Master Card" />
                    <img src={amexImg} alt="American Express" />
                    <img src={interacImg} alt="Interac Online" />
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
          <tr className="pad-top">
            <td>
              <OptionRadio value={PaymentMethod.DirectDepositOrCheque} />
            </td>
            <td>Pay using Direct Deposit or Cheque</td>
          </tr>
          <tr className="pad-top">
            <td>
              <OptionRadio value={PaymentMethod.Installment} />
            </td>
            <td>Pay in monthly installments</td>
          </tr>
          {paymentMethod === PaymentMethod.Installment ? (
            <tr>
              <td />
              <td>
                This option is provided by our partner First Insurance Funding
                and they will manage the process of collecting your payment.
                Once you e-sign the contract, you will receive your Premium
                Finance Agreement from them.
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentOptions;
