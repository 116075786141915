import React from 'react';
import './TableTitle.css';

const TableTitle: React.FC = ({ children }) => (
  <div
    className="TableTitle uppercase text-left p-2 text-2xl bg-secondary rounded-md border-none font-bold"
    data-testid="TableTitle"
  >
    {children}
  </div>
);

export default TableTitle;
