import React from 'react';
import './InvoiceDetails.css';
import { PaymentMethod } from '../../../generated/graphql';
import calTotalPayable from '../../../utils/calTotalPayable';

interface InvoiceDetailsProps {
  invoiceData: {
    invoiceDate: string;
    invoiceNumber: string;
    quoteId: string;
    tax: number;
    totalPremium: number;
  };
  paymentMethod: PaymentMethod;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoiceData,
  paymentMethod
}) => {
  const {
    invoiceDate,
    invoiceNumber,
    quoteId,
    tax,
    totalPremium
  } = invoiceData;

  const isCreditCard = paymentMethod === PaymentMethod.CreditCard;
  const { creditCardFee: fees, totalPayable: total } = calTotalPayable(
    invoiceData,
    paymentMethod
  );

  return (
    <table className="InvoiceDetails m-2" data-testid="InvoiceDetails">
      <thead>
        <tr>
          <th className="uppercase text-left" colSpan={2}>
            Invoice Details
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Invoice Number:</td>
          <td>{invoiceNumber}</td>
        </tr>
        <tr>
          <td>Invoice Date:</td>
          <td>{invoiceDate}</td>
        </tr>
        <tr>
          <td>Quote Number:</td>
          <td>{quoteId}</td>
        </tr>
        <tr>
          <td>Premium Amount:</td>
          <td>${totalPremium.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Taxes:</td>
          <td>${tax.toFixed(2)}</td>
        </tr>
        {isCreditCard ? (
          <tr>
            <td>Fees:</td>
            <td>${fees.toFixed(2)}</td>
          </tr>
        ) : null}
        <tr>
          <td>Total Payable:</td>
          <td>${total.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceDetails;
